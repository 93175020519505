import React, { useEffect } from "react";
import window from "global/window";

function Datachart() {
  useEffect(() => {
    window.location.href =
      "https://docs.google.com/spreadsheets/d/19NhiUx1gARgg6bcCl0fwZLbtEA3pkiT7xH2VCc7bqGo/edit?hl=en&hl=en&ui=2&chrome=false&rm=demo#gid=0";
  });
  return <></>;
}

export default Datachart;
